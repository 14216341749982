import Auth from './auth/Auth';
import { PagingObject, Artist, Album, SavedAlbum, SavedTrack } from 'types';

const rootUrl = 'https://api.spotify.com';

export default class Api {
  constructor(private auth: Auth) {}

  get = async (path: string): Promise<any> => {
    const result = await fetch(path, {
      headers: { Authorization: await this.getAuthorisation() },
    });

    return await result.json();
  };

  getAuthorisation = async (): Promise<string> => {
    return `Bearer ${await this.auth.getAccessToken()}`;
  };

  async *getAllMyArtists(): AsyncGenerator<Artist[]> {
    yield* this.getAll<Artist>(
      `${rootUrl}/v1/me/following?type=artist&limit=50`,
      'artists',
    );
  }

  async *getAllMySavedAlbums(): AsyncGenerator<SavedAlbum[]> {
    yield* this.getAll<SavedAlbum>(`${rootUrl}/v1/me/albums?limit=50`);
  }

  async *getAllMySavedTracks(): AsyncGenerator<SavedTrack[]> {
    yield* this.getAll<SavedTrack>(`${rootUrl}/v1/me/tracks?limit=50`);
  }

  async *getAll<T>(
    path: string,
    pagingObjectKey?: string,
  ): AsyncGenerator<T[]> {
    let nextPath = path;

    while (nextPath) {
      const response = await this.get(nextPath);
      const pagingObject: PagingObject<T> = pagingObjectKey
        ? response[pagingObjectKey]
        : response;
      nextPath = pagingObject.next;
      yield pagingObject.items;
    }
  }

  // Technically this API gives SimpleAlbums, not Albums.
  // But that makes the types way harder. Let's see if it causes any problems.
  async getAnArtistsAlbums(artistId: string): Promise<Album[]> {
    const response: PagingObject<Album> = await this.get(
      `${rootUrl}/v1/artists/${artistId}/albums?limit=50&include_groups=album,single,compilation`,
    );
    return response.items;
  }
}
