import React, { FC } from 'react';

export enum Tab {
  ARTISTS,
  ALBUMS,
  TRACKS,
}

type Props = {
  tab: Tab;
  setTab: (tab: Tab) => void;
  numArtists: number;
  numAlbums: number;
  numTracks: number;
};

const TabBar: FC<Props> = ({
  tab,
  setTab,
  numArtists,
  numAlbums,
  numTracks,
}) => {
  const artistTabClassName = tab === Tab.ARTISTS ? 'border-red-500' : '';
  const albumTabClassName = tab === Tab.ALBUMS ? 'border-green-500' : '';
  const trackTabClassName = tab === Tab.TRACKS ? 'border-blue-500' : '';

  return (
    <nav className="my-3">
      <ol className="flex">
        <li
          className={`text-2xl mx-2 px-1 border-b-2 hover:border-red-300 ${artistTabClassName}`}
        >
          <button onClick={() => setTab(Tab.ARTISTS)}>
            Artists <span className="text-lg">({numArtists})</span>
          </button>
        </li>
        <li
          className={`text-2xl mx-2 px-1 border-b-2 hover:border-green-300 ${albumTabClassName}`}
        >
          <button onClick={() => setTab(Tab.ALBUMS)}>
            Albums <span className="text-lg">({numAlbums})</span>
          </button>
        </li>
        <li
          className={`text-2xl mx-2 px-1 border-b-2 hover:border-blue-300 ${trackTabClassName}`}
        >
          <button onClick={() => setTab(Tab.TRACKS)}>
            Tracks <span className="text-lg">({numTracks})</span>
          </button>
        </li>
      </ol>
    </nav>
  );
};

export default TabBar;
