import React, { FC } from 'react';

type Props = any;

export const className =
  'border border-purple-600 h-10 px-3 hover:bg-purple-300';

const Button: FC<Props> = ({ className: extraClassName, ...props }) => (
  <button className={`${className} ${extraClassName}`} {...props} />
);
export default Button;
