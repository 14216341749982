import {
  AuthTokens,
  RefreshedToken,
  refreshAccessToken as realRefreshAccessToken,
} from './authApiCalls';

type refresher = (refreshToken: string) => Promise<RefreshedToken>;

class Auth {
  private storage: Storage;
  private now: () => number;
  private refreshAccessToken: refresher;

  constructor(
    storage: Storage,
    now: () => number,
    refreshAccessToken?: refresher,
  ) {
    this.storage = storage;
    this.now = now;
    this.refreshAccessToken = refreshAccessToken || realRefreshAccessToken;
  }

  public setTokens = (authTokens: AuthTokens) => {
    this.setAccessToken(authTokens.accessToken);
    this.setTokenExpiry(authTokens.expiresInSeconds);
    this.setRefreshToken(authTokens.refreshToken);
  };

  public getAccessToken = async (): Promise<string> => {
    if (this.accessTokenNotAlmostExpired()) {
      return this.storage.getItem('spotifyAccessToken');
    }
    const { accessToken, expiresInSeconds } = await this.refreshAccessToken(
      this.getRefreshToken(),
    );
    this.setAccessToken(accessToken);
    this.setTokenExpiry(expiresInSeconds);

    return accessToken;
  };

  private setAccessToken = (token: string) => {
    this.storage.setItem('spotifyAccessToken', token);
  };

  private setRefreshToken = (token: string) => {
    this.storage.setItem('spotifyRefreshToken', token);
  };

  private setTokenExpiry = (expiresInSeconds: number) => {
    const expiresAt = this.now() + expiresInSeconds * 1000;
    this.storage.setItem('spotifyTokenExpiresAt', `${expiresAt}`);
  };

  private getRefreshToken = (): string => {
    return this.storage.getItem('spotifyRefreshToken');
  };

  private accessTokenNotAlmostExpired = (): boolean => {
    const expiryString = this.storage.getItem('spotifyTokenExpiresAt');
    const expiresAt = parseInt(expiryString, 10);

    return expiresAt - this.now() > 60 * 1000;
  };
}

export default Auth;
