import React, { FC } from 'react';
import Csrf from './auth/Csrf';
import LinkButton from 'components/LinkButton';

const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const redirectUri = `${window.location.origin}/auth_callback`;
const csrfToken = `${Math.random()}`;
const scopes = [
  'playlist-modify-public',
  'user-follow-read',
  'user-library-read',
];
const loginUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&\
response_type=code&\
redirect_uri=${redirectUri}&\
state=${csrfToken}&\
scope=${scopes.join(',')}&\
`;

type Props = {
  csrf: Csrf;
};

const Welcome: FC<Props> = ({ csrf }) => {
  return (
    <main className="text-xl w-full max-w-5xl px-4 mx-auto">
      <h1 className="text-center text-3xl">Welcome to Spautofy</h1>
      <p>
        Spautofy helps you create playlists using advanced filtering and
        searching!
      </p>
      <ol className="list-disc pl-12">
        <li>Workout to exactly the right BPM!</li>
        <li>Party to only the most danceable, high-energy tracks!</li>
        <li>Chill out with some live acoustic performances</li>
      </ol>
      <p>
        And best of all, Spautofy only uses tracks from your own library, so you
        know you'll love every one!
      </p>
      <p>
        To get started, you'll need to log in to Spotify and give us permission
        to access your library. We'll use this to find all the music in your
        library, and to automatically create playlists (but only when you ask us
        to!).
      </p>
      <LinkButton
        className="mt-2"
        href={loginUrl}
        onClick={() => csrf.setCsrfToken(csrfToken)}
      >
        Log in with Spotify
      </LinkButton>
    </main>
  );
};

export default Welcome;
