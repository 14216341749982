import { Reducer } from 'react';
import { Artist, Album, Track } from 'types';

type State = {
  artists: Artist[];
  albums: Album[];
  tracks: Track[];

  startedLoading: boolean;
  error: any;

  artistsFinished: boolean;
  albumsFinished: boolean;
  tracksFinished: boolean;
};

type Action =
  | { type: 'startLoading' }
  | { type: 'receiveArtists'; artists: Artist[] }
  | { type: 'receiveAlbums'; albums: Album[] }
  | { type: 'receiveTracks'; tracks: Track[] }
  | { type: 'finishArtists' }
  | { type: 'finishAlbums' }
  | { type: 'finishTracks' }
  | { type: 'errorLoading'; error: any };

export const initialState: State = {
  artists: [],
  albums: [],
  tracks: [],
  startedLoading: false,
  error: null,
  artistsFinished: false,
  albumsFinished: false,
  tracksFinished: false,
};

interface WithId {
  id: string;
}

const concatUnique = <T extends WithId>(existing: T[], received: T[]): T[] => {
  const newElements = received.filter(
    (candidate) => !existing.find((e) => e.id === candidate.id),
  );
  return existing.concat(newElements);
};

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action,
): State => {
  switch (action.type) {
    case 'startLoading': {
      return { ...initialState, startedLoading: true };
    }
    case 'receiveArtists': {
      return { ...state, artists: concatUnique(state.artists, action.artists) };
    }
    case 'receiveAlbums': {
      return { ...state, albums: concatUnique(state.albums, action.albums) };
    }
    case 'receiveTracks': {
      return { ...state, tracks: concatUnique(state.tracks, action.tracks) };
    }
    case 'finishArtists': {
      return { ...state, artistsFinished: true };
    }
    case 'finishAlbums': {
      return { ...state, albumsFinished: true };
    }
    case 'finishTracks': {
      return { ...state, tracksFinished: true };
    }
    case 'errorLoading': {
      return { ...state, error: action.error };
    }
  }
};
