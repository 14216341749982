import React, { FC } from 'react';
import { Img } from 'types';
import Square from 'components/Square';

interface GridElement {
  id: string;
  name: string;
  images: Img[];
}

type Props = {
  elements: GridElement[];
};

const sortedByName = (elements: GridElement[]): GridElement[] =>
  [...elements].sort((e1, e2) =>
    e1.name < e2.name ? -1 : e1.name > e2.name ? 1 : 0,
  );

const firstSquare = (images: Img[]): Img =>
  images.find((image) => image.width === image.height) || images[0];

const ImageGrid: FC<Props> = ({ elements }) => {
  return (
    <ul className="grid grid-flow-row gap-2 grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6">
      {sortedByName(elements).map((element: GridElement) => (
        <li key={element.id} className="text-center h-f">
          <Square>
            <img src={firstSquare(element.images)?.url} alt={element.name} />
          </Square>
          {element.name}
        </li>
      ))}
    </ul>
  );
};

export default ImageGrid;
