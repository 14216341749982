import React, { FC } from 'react';
import { Track } from 'types';

type Props = {
  tracks: Track[];
};

type CellProps = {
  right?: boolean;
  hideOnMobile?: boolean;
  children?: any;
};

const cellClasses = (right: boolean, hideOnMobile: boolean) =>
  `px-3 h-4 ${right ? 'text-right' : ''} ${
    hideOnMobile ? 'hidden sm:table-cell' : ''
  }`;

const Th: FC<CellProps> = ({ right, hideOnMobile, children }) => (
  <th className={cellClasses(right, hideOnMobile)}>{children}</th>
);
const Td: FC<CellProps> = ({ right, hideOnMobile, children }) => (
  <td className={cellClasses(right, hideOnMobile)}>{children}</td>
);

const twoDigits = (n: number) => {
  const str = n.toString();
  return str.length >= 2 ? str : `0${str}`;
};

const msToMinSec = (ms: number): string => {
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes}:${twoDigits(seconds)}`;
};

const sortingFields = ({
  artists: [{ name: artist }],
  album: { release_date: album },
  track_number: number,
}: Track) => ({ artist, album, number });

const sortedByName = (elements: Track[]): Track[] =>
  [...elements].sort((t1, t2) => {
    const { artist: artist1, album: album1, number: num1 } = sortingFields(t1);
    const { artist: artist2, album: album2, number: num2 } = sortingFields(t2);

    const numberComparison = num1 < num2 ? -1 : num1 > num2 ? 1 : 0;
    const albumComparison =
      album1 < album2 ? -1 : album1 > album2 ? 1 : numberComparison;
    const artistComparison =
      artist1 < artist2 ? -1 : artist1 > artist2 ? 1 : albumComparison;

    return artistComparison;
  });

const TrackTable: FC<Props> = ({ tracks }) => {
  return (
    <table>
      <thead>
        <tr className="text-left">
          <Th right hideOnMobile>
            Length
          </Th>
          <Th right hideOnMobile>
            #
          </Th>
          <Th>Track</Th>
          <Th>Artist</Th>
          <Th hideOnMobile>Album</Th>
        </tr>
      </thead>
      <tbody>
        {sortedByName(tracks).map((track) => (
          <tr
            className="py-2 even:bg-gray-100 hover:bg-blue-100"
            key={track.id}
          >
            <Td right hideOnMobile>
              {msToMinSec(track.duration_ms)}
            </Td>
            <Td right hideOnMobile>
              {track.track_number}
            </Td>
            <Td>{track.name}</Td>
            <Td>{track.artists.map((a) => a.name).join(', ')}</Td>
            <Td hideOnMobile>{track.album.name}</Td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TrackTable;
