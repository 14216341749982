import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Auth from './auth/Auth';
import Csrf from './auth/Csrf';
import AuthCallback from './auth/AuthCallback';
import Header from './Header';
import Welcome from './Welcome';
import ImportPage from './pages/import/ImportPage';
import Api from './Api';

const auth = new Auth(window.localStorage, Date.now);
const csrf = new Csrf(window.localStorage);
const api = new Api(auth);

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Welcome csrf={csrf} />
        </Route>
        <Route path="/auth_callback">
          <AuthCallback auth={auth} csrf={csrf} />
        </Route>
        <Route path="/">
          <Header />
          <Route path="/import">
            <ImportPage api={api} />
          </Route>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
