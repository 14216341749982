import React from 'react';
import { Link } from 'react-router-dom';

const TryAgainLink = () => (
  <>
    Please{' '}
    <Link className="underline" to="/">
      try again
    </Link>
    .
  </>
);

export default TryAgainLink;
