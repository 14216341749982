import React, { FC } from 'react';
import Button from 'components/Button';

type Props = {
  started: boolean;
  finished: boolean;
  refresh: () => Promise<void>;
};

const TitleRow: FC<Props> = ({ started, finished, refresh }) => {
  return (
    <div className="flex justify-between items-end pb-2 border-b-2 mb-2 border-gray-600">
      <h2 className="text-3xl">Import</h2>

      {started &&
        (finished ? (
          <Button
            className={finished ? 'visible' : 'invisible'}
            onClick={refresh}
          >
            Refresh
          </Button>
        ) : (
          'Loading...'
        ))}
    </div>
  );
};

export default TitleRow;
