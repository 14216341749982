import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import GetAccessToken from './GetAccessToken';
import TryAgainLink from './TryAgainLink';
import Auth from './Auth';
import Csrf from './Csrf';

type Props = {
  auth: Auth;
  csrf: Csrf;
};

const AuthCallback: FC<Props> = ({ auth, csrf }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  if (!csrf.isCsrfTokenValid(searchParams.get('state'))) {
    return (
      <p className="text-center">
        Error: CSRF token mismatch.
        <TryAgainLink />
      </p>
    );
  }

  const error = searchParams.get('error');

  if (error) {
    return (
      <p className="text-center">
        Error: {error}. <TryAgainLink />
      </p>
    );
  }

  return <GetAccessToken code={searchParams.get('code')} auth={auth} />;
};

export default AuthCallback;
