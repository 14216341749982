export default class Csrf {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage || window.localStorage;
  }

  public setCsrfToken = (token: string) => {
    this.storage.setItem('spotifyAuthCsrfToken', token);
  };

  public isCsrfTokenValid = (tokenToCheck: string): boolean => {
    return this.storage.getItem('spotifyAuthCsrfToken') === tokenToCheck;
  };
}
