import React, { FC, useReducer } from 'react';
import { Artist, SavedAlbum, SavedTrack } from 'types';
import Api from 'Api';
import Button from 'components/Button';
import { reducer, initialState } from './state';
import TitleRow from './TitleRow';
import Library from './Library';

type Props = {
  api: Api;
};

const streamData = async <T extends {}>(
  generator: AsyncGenerator<T[]>,
  onReceive: (t: T[]) => void,
  onFinish: () => void,
  onError: (e: unknown) => void,
) => {
  try {
    for await (let stream of generator) {
      onReceive(stream);
    }
    onFinish();
  } catch (error) {
    onError(error);
  }
};

const ImportPage: FC<Props> = ({ api }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { artists, albums, tracks } = state;
  const { artistsFinished, albumsFinished, tracksFinished } = state;
  const { startedLoading, error } = state;

  const finished = artistsFinished && albumsFinished && tracksFinished;

  const loadData = async () => {
    dispatch({ type: 'startLoading' });
    streamData<Artist>(
      api.getAllMyArtists(),
      (newArtists) => {
        dispatch({ type: 'receiveArtists', artists: newArtists });
        newArtists.forEach(async (artist) => {
          const theArtistsAlbums = await api.getAnArtistsAlbums(artist.id);
          dispatch({ type: 'receiveAlbums', albums: theArtistsAlbums });
        });
      },
      () => dispatch({ type: 'finishArtists' }),
      (e) => dispatch({ type: 'errorLoading', error: e }),
    );
    streamData<SavedAlbum>(
      api.getAllMySavedAlbums(),
      (s) => dispatch({ type: 'receiveAlbums', albums: s.map((a) => a.album) }),
      () => dispatch({ type: 'finishAlbums' }),
      (e) => dispatch({ type: 'errorLoading', error: e }),
    );
    streamData<SavedTrack>(
      api.getAllMySavedTracks(),
      (s) => dispatch({ type: 'receiveTracks', tracks: s.map((t) => t.track) }),
      () => dispatch({ type: 'finishTracks' }),
      (e) => dispatch({ type: 'errorLoading', error: e }),
    );
  };

  return (
    <main className="px-5 py-3 max-w-6xl m-auto">
      <TitleRow
        started={startedLoading}
        finished={finished || error}
        refresh={loadData}
      />

      {error ? (
        <p>
          Sorry, but we couldn't import your library just now. Maybe try again?
        </p>
      ) : startedLoading ? (
        <Library artists={artists} albums={albums} tracks={tracks} />
      ) : (
        <>
          <p className="my-3">
            Let's start by importing your library. We'll look up all the artists
            you follow on Spotify, then grab all of their albums, and all of the
            tracks from those albums.
          </p>
          <p className="my-3">
            If you start following new artists, come back to this page and click
            'Refresh'.
          </p>
          <Button onClick={loadData}>Ok, let's go!</Button>
        </>
      )}
    </main>
  );
};

export default ImportPage;
