import React, { FC } from 'react';
import { className as buttonClassName } from './Button';

type Props = any;

const LinkButton: FC<Props> = ({ className, children, ...props }) => {
  return (
    <a
      className={`${buttonClassName} inline-flex items-center ${className}`}
      {...props}
    >
      {children}
    </a>
  );
};

export default LinkButton;
