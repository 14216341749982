import React, { FC, useState } from 'react';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import MenuIcon from './icons/MenuIcon';

const Li = ({ className = '', ...props }) => (
  <li
    className={`h-10 flex justify-end items-center ${className}`}
    {...props}
  />
);

const NavLink = (props) => (
  <RRNavLink
    className="underline mr-3"
    activeClassName="font-bold"
    {...props}
  />
);

const Header: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const mobileDisplay = isOpen ? 'block' : 'hidden';
  const mobileNavClassNames = `absolute right-0 top-0 pb-3 bg-purple-500 mt-10 ${mobileDisplay}`;
  const regularNavClassNames = `sm:static sm:inline sm:bg-transparent sm:mt-0 sm:flex-1 sm:pb-0`;

  return (
    <header className="flex h-10 pl-3 bg-purple-300 shadow-2xl items-center justify-between">
      <h1 className="text-2xl">
        <Link to="/">Spautofy</Link>
      </h1>
      <MenuIcon
        className="h-full px-2 py-1 cursor-pointer sm:hidden"
        onClick={() => setIsOpen(!isOpen)}
      />
      <nav className={`${mobileNavClassNames} ${regularNavClassNames}`}>
        <ol className="sm:flex sm:justify-center">
          <Li className="ml-10">
            <NavLink onClick={() => setIsOpen(false)} to="/import">
              Import
            </NavLink>
          </Li>
          <Li className="ml-10">
            <NavLink onClick={() => setIsOpen(false)} to="/vibe">
              Vibe Check
            </NavLink>
          </Li>
          <Li className="ml-10">
            <NavLink onClick={() => setIsOpen(false)} to="/override">
              Override
            </NavLink>
          </Li>
          <Li className="ml-10">
            <NavLink onClick={() => setIsOpen(false)} to="/review">
              Review
            </NavLink>
          </Li>
        </ol>
      </nav>
    </header>
  );
};

export default Header;
