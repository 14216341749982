import React, { useState, useEffect, FC } from 'react';
import { Redirect } from 'react-router-dom';
import TryAgainLink from './TryAgainLink';
import Auth from './Auth';
import {
  exchangeCodeForTokens as realExchangeCodeForTokens,
  AuthTokens,
} from './authApiCalls';

enum RequestState {
  pending,
  success,
  failed,
}

type Props = {
  code: string;
  auth: Auth;
  exchangeCodeForTokens?: () => Promise<AuthTokens>;
};

const GetAccessToken: FC<Props> = ({
  code,
  auth,
  exchangeCodeForTokens = realExchangeCodeForTokens,
}) => {
  const [requestState, setRequestState] = useState(RequestState.pending);

  useEffect(() => {
    exchangeCodeForTokens(code).then(
      (authTokens: AuthTokens) => {
        auth.setTokens(authTokens);

        setRequestState(RequestState.success);
      },
      () => {
        setRequestState(RequestState.failed);
      },
    );
  }, [code, auth, exchangeCodeForTokens]);

  switch (requestState) {
    case RequestState.pending:
      return <p className="text-center">Loading...</p>;
    case RequestState.failed:
      return (
        <p className="text-center">
          Error. <TryAgainLink />
        </p>
      );
    case RequestState.success:
      return <Redirect to="/import" />;
  }
};

export default GetAccessToken;
