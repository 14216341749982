import React, { FC, useState } from 'react';
import { Artist, Album, Track } from 'types';
import TabBar, { Tab } from './TabBar';
import ImageGrid from './ImageGrid';
import TrackTable from './TrackTable';

type Props = {
  artists: Artist[];
  albums: Album[];
  tracks: Track[];
};

const Library: FC<Props> = ({ artists, albums, tracks }) => {
  const [tab, setTab] = useState(Tab.ARTISTS);
  return (
    <>
      <TabBar
        tab={tab}
        setTab={setTab}
        numArtists={artists.length}
        numAlbums={albums.length}
        numTracks={tracks.length}
      />
      {tab === Tab.ARTISTS && <ImageGrid elements={artists} />}
      {tab === Tab.ALBUMS && <ImageGrid elements={albums} />}
      {tab === Tab.TRACKS && <TrackTable tracks={tracks} />}
    </>
  );
};

export default Library;
