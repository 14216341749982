import React, { FC } from 'react';

type Props = {
  children: React.ReactChild;
};

// Method from here: https://css-tricks.com/aspect-ratio-boxes/

const Square: FC<Props> = ({ children }) => {
  return (
    <div className={`h-0 pt-full overflow-hidden relative`}>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        {children}
      </div>
    </div>
  );
};

export default Square;
