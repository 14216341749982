const postJson = async (url: string, body: object) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (res.ok) {
    return res.json();
  }
  return Promise.reject(res.json());
};

export type AuthTokens = {
  accessToken: string;
  expiresInSeconds: number;
  refreshToken: string;
};

export const exchangeCodeForTokens = async (
  code: string,
): Promise<AuthTokens> => {
  return postJson('/.netlify/functions/exchangeCodeForTokens', { code }).then(
    ({ access_token, expires_in, refresh_token }) => ({
      accessToken: access_token,
      expiresInSeconds: expires_in,
      refreshToken: refresh_token,
    }),
  );
};

export type RefreshedToken = Omit<AuthTokens, 'refreshToken'>;

export const refreshAccessToken = async (
  refreshToken: string,
): Promise<RefreshedToken> => {
  return postJson('/.netlify/functions/refreshAccessToken', {
    refreshToken,
  }).then(({ access_token, expires_in }) => ({
    accessToken: access_token,
    expiresInSeconds: expires_in,
  }));
};
